import React from 'react';
import { Card, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';

import { comites } from '../../utils/tu-instituto/comites';
import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';
import { navigate } from 'gatsby';

const OrganosColegiados = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Órganos Colegiados" />

      <div className={globalClasses.container}>
        <Header title="Órganos colegiados" />

        <div className={globalClasses.content}>
          <Grid container spacing={4}>
            {comites.map((comite, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.card} onClick={e => navigate(`/tu-instituto/comite?comite=${comite.abreviatura}`)}>
                  <CardHeader
                    disableTypography
                    title={<Typography variant="h5" style={{ fontWeight: 'normal' }}>{comite.name}</Typography>}
                    action={
                      <IconButton color='primary' disabled style={{ color: 'rgba(157,36,73,0.55)' }}>
                        <ArrowForward />
                      </IconButton>
                    }
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default OrganosColegiados;